import { FeatureData } from '@innedit/innedit';
import { DocumentType, PaiementType } from '@innedit/innedit-type';
import { PageProps } from 'gatsby';
import React, { FC, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import Button from '../../components/Button';
import TemplateUser from '../../templates/user';
import requireUser, { UserProps } from '../../utils/requireUser';

const PageUtilisateurPaiements: FC<PageProps & UserProps> = ({
  params: { espaceId },
  user,
}) => {
  const [paiements, setPaiements] = useState<DocumentType<PaiementType>[]>();

  useEffect(() => {
    let isMounted = true;
    const featureData = new FeatureData<PaiementType>({
      espaceId,
      collectionName: 'paiements',
    });

    if (user.customer) {
      featureData
        .find({
          wheres: {
            user: user.id,
          },
        })
        .then(docs => {
          if (isMounted) {
            setPaiements(docs as DocumentType<PaiementType>[]);
          }

          return true;
        })
        .catch(toast.error);
    }

    return () => {
      isMounted = false;
    };
  }, [user.id]);

  return (
    <TemplateUser title="Mes paiements">
      {paiements && (
        <ul>
          {paiements.map(paiement => (
            <li key={paiement.id}>
              <Button to={`/${paiement.id}/`} variant="link">
                {paiement.amount}
              </Button>
            </li>
          ))}
        </ul>
      )}
    </TemplateUser>
  );
};

export default requireUser(PageUtilisateurPaiements);
